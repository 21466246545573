import Collectibles from "../routes/collectibles/collectibles";
import Boosters from "../routes/boosters/boosters";
import ComicReader from "../routes/comic-reader/comic-reader";
import Footer from "../components/footer/footer";
import Home from "../routes/home/home";
import Navbar from "../components/navbar/navbar";
import { PublicKey } from "@solana/web3.js";
import { Routes, Route } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
    useWallet,
} from '@solana/wallet-adapter-react';
import * as anchor from '@project-serum/anchor';
import NotFound from "../components/notFound/notFound";
import Hoverboards from "../routes/hoverboards/hoverboards";
import Admin from "../routes/admin/admin";

const Main = () => {
    // eslint-disable-next-line
    const [comicCollection, setComicCollection] = useState<any[] | undefined>(undefined);
    const [isCollectionFetched, setIsCollectionFetched] = useState<boolean>(false);

    const wallet = useWallet();

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);


    useEffect(() => {
        if (anchorWallet) {
            if (!isCollectionFetched) {
                setIsCollectionFetched(true);
                getComicCollection(anchorWallet);
            }
        }
    }, [anchorWallet, isCollectionFetched])

    const getComicCollection = (anchorWallet: { publicKey: PublicKey }) => {
        try {
            let walletPubkey: string = anchorWallet.publicKey?.toString()

            const options: RequestInit = {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "pubKey": walletPubkey,
                }),
            }

            fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/comics/collection`, options)
                .then(res => res.json())
                .then(res => {
                    if (res && res.pubKey) {
                        if (res.pubKey !== walletPubkey) {
                            return;
                        } else {
                            setComicCollection(res.data);
                        }
                    }
                })
                .catch(err => { console.log(err) })

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="relative w-screen">
            <Navbar />
            <Routes>
                <Route path="/" element={
                    <Home comicCollection={comicCollection} wallet={wallet} getComicCollection={getComicCollection} />
                } />
                <Route path={"/comic-reader/:comicId"} element={
                    <ComicReader comicCollection={comicCollection} wallet={wallet} />
                } />
                <Route path={"/boosters"} element={
                    <Boosters wallet={wallet} />
                } />
                <Route path={"/collectibles"} element={
                    <Collectibles wallet={wallet} />
                } />
                <Route path={"/hoverboards"} element={
                    <Hoverboards wallet={wallet} />
                } />
                <Route path={"/admin"} element={
                    <Admin wallet={wallet} />
                } />
                <Route path={"*"} element={
                    <NotFound />
                } />
            </Routes>
            <Footer />
        </div>
    )
}

export default Main;